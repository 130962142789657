import React from 'react';
import { GetServerSideProps } from 'next';

// utils
import { fetchNavHTML } from '~/libs/server/nav';
import { fetchUser } from '~/libs/server/users';
import { UserContext } from '@zola-helpers/client/dist/es/@types';

// Components
import { FaqCategory } from '~/libs/contentful/faqs/types';
import { fetchFaqLandingPage } from '~/libs/contentful/faqs/api';
import FaqPagesContainer from '~/components/FaqHelp/FaqPagesContainer';
import { Container, CategoriesList } from '~/components/FaqHelp/styles/FaqLandingPage.styles';
import FaqCategoryCard from '~/components/FaqHelp/FaqCategoryCard';
import FAQSearchHero from '~/components/FaqHelp/FAQSearchHero';
import Head from 'next/head';
import logger from '@zola-helpers/server/dist/es/logger';

export type FaqLandingPageProps = {
  navHTML: string;
  userCtx: UserContext;
  categories: FaqCategory[];
};

const title = 'Frequently Asked Questions about Zola';
const description =
  'Get to know everything about us and our services on our FAQs page. Still need help? Contact us for 24/7 support.';

const FaqLandingPage: React.FC<FaqLandingPageProps> = ({ navHTML, userCtx, categories }) => {
  return (
    <FaqPagesContainer navHTML={navHTML} userCtx={userCtx}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="zola.com" />
        <meta name="twitter:site" content="@zola" />
        <meta name="twitter:card" content="summary" />
        <link rel="canonical" href="https://www.zola.com/faq" />
      </Head>
      <Container>
        <FAQSearchHero />
        <CategoriesList>
          {categories.map((c, i) => (
            <FaqCategoryCard category={c} key={i} />
          ))}
        </CategoriesList>
      </Container>
    </FaqPagesContainer>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const [navHTML, userCtx] = await Promise.all([fetchNavHTML(req), fetchUser(req)]);

  const landingPage = await fetchFaqLandingPage();
  if (!landingPage) {
    logger.error('Faqs landing page not found');
    return { notFound: true };
  }
  const { categories } = landingPage;

  return {
    props: JSON.parse(
      JSON.stringify({
        navHTML,
        userCtx,
        categories,
      })
    ),
  };
};

export default FaqLandingPage;
