import React, {
  useState,
  ChangeEvent,
  useEffect,
  useCallback,
  useRef,
  KeyboardEventHandler,
} from 'react';
import _debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { SearchIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Search';
import { XIcon } from '@zola/zola-ui/src/components/SvgIconsV3/X';
import { FaqArticleSearchResult, searchFaqs } from '~/libs/client/faq/api';
import { useClickOutside, usePrevious } from '@zola/zola-ui/src/hooks';
import { ScreenReaderText } from '@zola/zola-ui/src/components/Modal/ModalV2.styles';

import {
  Container,
  ResultsContainer,
  SearchInput,
  ResultsList,
  ResultOption,
  DropdownText,
  SectionTitle,
  CloseButton,
} from './FaqSearchDropdown.styles';

type FaqSearchDropdownProps = {
  className?: string;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const FaqSearchDropdown = ({ className }: FaqSearchDropdownProps): JSX.Element => {
  const [inputValue, setInputValue] = useState('');
  const [busy, setBusy] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [results, setResults] = useState<FaqArticleSearchResult[]>([]);
  const [activeResult, setActiveResult] = useState<number>();
  const router = useRouter();

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const prevInput = usePrevious(inputValue);
  const inputValueChanged = inputValue !== prevInput;
  const fetchSearchResults = useCallback(() => {
    if (!busy && inputValueChanged) {
      setBusy(true);
      searchFaqs({ query: inputValue })
        .then(res => {
          if (res?.length) {
            setResults(res);
            setShowDropdown(true);
          }
          setBusy(false);
        })
        .catch(() => {
          setBusy(false);
        });
    }
  }, [busy, inputValueChanged, inputValue]);

  const debouncedSearch = _debounce(fetchSearchResults, 300, { trailing: true });

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue) {
      if (activeResult) {
        const activeResultSlug = results[activeResult - 1].slug;
        router.push(`/faq/${activeResultSlug}`).catch(() => undefined);
      } else {
        router.push(`/faq/search?term=${inputValue}`).catch(() => undefined);
      }
    }
    if (event.key === 'ArrowDown' && results.length && showDropdown) {
      event.preventDefault();
      const current = activeResult || 0;
      const isOnLast = activeResult === results.length;
      if (!isOnLast) {
        setActiveResult(current + 1);
      } else {
        setActiveResult(undefined);
      }
    }
    if (event.key === 'ArrowUp' && results.length && showDropdown) {
      event.preventDefault();
      const current = activeResult || 0;
      const isFirst = activeResult === 1;
      if (!current) {
        setActiveResult(results.length);
      } else if (isFirst) {
        setActiveResult(undefined);
      } else {
        setActiveResult(current - 1);
      }
    }
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside({
    ref: dropdownRef,
    onClickOutside: () => setShowDropdown(false),
    isOpen: showDropdown,
  });

  useEffect(() => {
    if (inputValue.length > 3) {
      // Disable typeahead search until pefomance issues are resolved
      // debouncedSearch();
    }
  }, [inputValue.length, debouncedSearch]);

  const highlightSearchTerm = (text: string, term: string) => {
    const searchTermRegex = new RegExp(term, 'gi');
    const highlighted = text.replace(searchTermRegex, `<strong>${term}</strong>`);
    // eslint-disable-next-line react/no-danger
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  const handleCloseClick = () => {
    setShowDropdown(false);
    setInputValue('');
  };

  const handleClosePressEnter: KeyboardEventHandler<HTMLButtonElement> = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      handleCloseClick();
    }
  };

  return (
    <Container className={className}>
      <SearchIcon width={20} height={20} />
      <ScreenReaderText id="instructions" style={{ display: 'none' }}>
        Begin typing to search FAQs, use arrow keys to navigate, Enter to select
      </ScreenReaderText>
      <ScreenReaderText aria-live="assertive">{results.length} results found</ScreenReaderText>
      <SearchInput
        placeholder="Type your question..."
        value={inputValue}
        onChange={onInputChange}
        onKeyDown={handleInputKeyDown}
        aria-controls=""
        aria-haspopup="listbox"
        aria-expanded={showDropdown}
        aria-describedby="#instructions"
        aria-activedescendant={activeResult ? `#result-${activeResult}` : undefined}
      />
      {inputValue ? (
        <CloseButton onClick={handleCloseClick} onKeyDown={handleClosePressEnter}>
          <XIcon width={24} height={24} />
          <ScreenReaderText>Clear results</ScreenReaderText>
        </CloseButton>
      ) : null}
      {results.length && showDropdown ? (
        <ResultsContainer ref={dropdownRef}>
          <DropdownText>Top article suggestions</DropdownText>
          <ResultsList role="listbox">
            {results.map((r, i) => {
              return (
                <ResultOption key={r.slug} active={activeResult === i + 1}>
                  <a href={`/faq/${r.slug}`} id={`result-${i + 1}`}>
                    {highlightSearchTerm(r.title, r.searchTerm)}{' '}
                    <SectionTitle>{r.sectionTitle}</SectionTitle>
                  </a>
                </ResultOption>
              );
            })}
          </ResultsList>
        </ResultsContainer>
      ) : null}
    </Container>
  );
};

export default FaqSearchDropdown;
